import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>About</h1>
      <p>
        My name is Derek and I am a developer from Atlanta, Georgia! I utilize a
        modern tech stack with React, GraphQL, GatsbyJS and other frameworks.
      </p>
      <Link to="/contact">Contact me.</Link>
    </Layout>
  )
}

export default AboutPage
